.clientSection{
    width: 100%;
    height: fit-content;
    margin-bottom: 50px;
    margin-top: 50px;
    /* padding: 65px 8% 25px 8%; */
}

.client-scroller-wp{
  width: 90%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid var(--grey);
  border-radius: 25px;
  padding: 0 5% 5% 5%;
  /* display: flex;
  justify-content: center; */
}
.client-scroller-wp .header{
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 0 15px 0 15px;
  background-color: var(--body);
}
.client-scroller-wp .header .text{
  display: inline-block;
}




/* Glidar */
.glidar{
  width: 100%;
  height: 200px;
  background-color: white;
  border-radius: 25px;
}

.glidar .header{
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  min-width: 150px;
  padding: 10px;
  border-radius: 15px;
  background-color: #ffd382;
  text-align: center;
}

.slidar{
  width: 90%;
  height: 150px;
  margin: 0px auto 0 auto;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slidar-track{
  display: flex;
  width: fit-content;
  animation: scroll 10s linear infinite;
}
.slidar-track:hover{
  animation-play-state: paused;
}
.silde-box{
  width: 150px;
  height: 50px;
  overflow: hidden;
  position: relative;
  margin: 0 15px 0 15px;
}
.silde-box-img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


@keyframes scroll {
  0% {
   transform:translateX(0)
  }
  100% {
   transform:translateX(-50%)
  }
 }












/* .clientSection h4{
    margin-bottom: 30px;
}
.glyder-container{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}
.glyder{
    width: 200%;
    height: 100%;
    display: flex;
    animation: rotation 30s linear infinite;
}
.glyder img{
    height: 150px;
    margin: 0 25px 0 25px;
}
@keyframes rotation {
    0% {
     transform:translateX(0)
    }
    100% {
     transform:translateX(-100%)
    }
   }

   body{
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh
  }
  .auto_slider_container{
    max-width: 1000px;
    width: 100%;
    display: flex;
    margin: auto;
    overflow: hidden;
  }
  .auto_slider{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotation 30s linear infinite;
  }
  .auto_slider li{
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    width: 100%;

  } */
