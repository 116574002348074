.footer{
    width: 100%;
    height: fit-content;
    margin-bottom: 50px;
    padding: 0 8% 0 8%;
    display: flex;
}
.ft-brand-wp{
    width: 30%;
    height: 100%;
}
.ft.brand-logo{
    width: 200px;
}
.ft-brand-wp .text1{
    font-size: 2.8rem;
    font-weight: 600;
}
.ft-brand-wp .text2{
    font-size: 1rem;
    font-weight: 400;
    margin-top: 12px;
    color: var(--grey);
}

.ft-social-wp{
    /* display: flex;
    flex-direction: column; */
    /* align-items: center; */
    width: 70%;
    padding-left: 5%;
}
.ft-social-wp>.text{
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    padding-left: 15px;
    margin-top: 15px;
    text-align: left;
}
.social-icon-wp{
    display: flex;

}
.social-icon{
    width: 24px;
    height: 24px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
    cursor: pointer;
}


@media screen and (max-width: 1100px) {
    .ft-brand-wp{
        width: 50%;
        height: 100%;
    }
    .ft-social-wp{
        /* display: flex;
        flex-direction: column; */
        /* align-items: center; */
        width: 50%;
        padding-left: 5%;
    }
}

@media screen and (max-width: 900px) {
    .ft-brand-wp{
        width: 35%;
    }
    .ft-brand-wp .text1{
        font-size: 1.8rem;
    }
    .ft-brand-wp .text2{
        font-size: 0.8rem;
    }
    .ft-social-wp{
        /* display: flex;
        flex-direction: column; */
        /* align-items: center; */
        width: 65%;
    }
    .ft-social-wp>.text{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .ft-brand-wp{
        width: 35%;
    }
    .ft-brand-wp .text1{
        font-size: 1.5rem;
    }
    .ft-brand-wp .text2{
        text-align: justify;
        font-size: 0.8rem;
    }
    .ft-social-wp{
        /* display: flex;
        flex-direction: column; */
        /* align-items: center; */
        width: 65%;
    }
    .ft-social-wp>.text{
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 600px) {
    .footer{
        flex-direction: column;
    }
    .ft-brand-wp{
        width: 100%;
    }
    .ft-brand-wp .text1{
        font-size: 1.5rem;
    }
    .ft-brand-wp .text2{
        text-align: justify;
        font-size: 0.8rem;
    }
    .ft-social-wp{
        /* display: flex;
        flex-direction: column; */
        /* align-items: center; */
        width: 100%;
        padding-left: 0;
    }
    .ft-social-wp>.text{
        font-size: 1.2rem;
        padding-left: 0;
    }
}