.ourStories{
    width: 100%;
    height: 70vh;
    display: flex;
    padding: 0 8% 0 6%;
}
.os-img-wp{
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}
.os-img-wp img{
    width: 110%;
    height: 110%;
    object-fit: contain;

}
.os-content-wp{
    width: 40%;
}
.os-content-wp>h1{
    margin-top: 100px;
    font-size: 3.8rem;
    font-weight: 500;
}
.os-content-wp>p{
    color: var(--grey); 
    font-size: 0.9rem;
    margin-top: 25px;
}
.tag-wp{
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 45px;
}
.tag{
    width: fit-content;
    box-sizing: content-box;
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 1px 15px 15px #44444418;
}
.tag h3{
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 500;
}
.tag p{
    font-size: 1rem;
    margin-bottom: 10px;
    color: #444;
    font-weight: 600;
    line-height: 10px;
}

@media screen and (max-width: 1200px) {
    .os-img-wp{
        width: 50%;
    }
    .os-content-wp{
        width: 40%;
        margin-left: 10%;
    }
    .os-content-wp>h1{
        font-size: 3.2rem;
    }
    .os-img-wp img{
        width: 120%;
        height: 120%;
        object-fit: contain;
    
    }
    .tag-wp{
        width: 100%;
    }
    .os-content-wp{
        width: 50%;
    }

}
@media screen and (max-width: 1100px) {
    .os-content-wp>h1{
        font-size: 3rem;
    }
    .tag h3{
        font-size: 2rem;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .tag p{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1100px) {
    .os-content-wp{
        margin-top: 100px;
    }
    .os-content-wp>h1{
        font-size: 2.8rem;
        margin-bottom: 25px;
    }
    .os-content-wp>p{

        margin-top: 15px;
    }
    .tag-wp{
        margin-top: 25px;
    }
}

@media screen and (max-width: 1000px) {
    .os-content-wp>h1{
        font-size: 2.5rem;
        margin-bottom: 25px;

    }

    .tag h3{
        font-size: 1.8rem;
    }
    .tag p{
        font-size: 0.9rem;
    }
    .tag-wp{
        margin-top: 25px;
        width: 110%;
    }
}
@media screen and (max-width: 950px) {
    .tag h3{
        font-size: 1.5rem;
    }
    .tag p{
        font-size: 0.8rem;
    }
}
@media screen and (max-width: 800px) {
    .tag h3{
        font-size: 1.4rem;
    }
    .tag p{
        font-size: 0.75rem;
    }
    .tag-wp{
        margin-top: 25px;
        width: 120%;
        margin-left: -10%;
    }
}
@media screen and (max-width: 730px) {
    .ourStories{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 0 8% 0 8%;
        margin-top: 100px;

    }
    .os-img-wp{
        width: 100%;
        height: 50%;
        margin-left: 5%;
    }
    .os-img-wp img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    
    }
    .os-content-wp{
        width: 100%;
        height: fit-content;
        margin-top: 50px;
        margin-left: 0%;
    }
    .os-content-wp>h1{
        margin-top: 0px;
        text-align: center;
    }
    .os-content-wp>p{
        text-align: center;
    }
    .tag-wp{
        margin-top: 25px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    
}
@media screen and (max-width: 650px) {
    .os-img-wp{
        margin-left: 4%;
    }
    .os-content-wp{
        margin-top: 40px;
    }
    .tag-wp{
        margin-top: 25px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 500px) {
    .tag-wp{
        width: 90%;
    }
}
@media screen and (max-width: 450px) {
    .tag-wp{
        width: 100%;
    }
}
@media screen and (max-width: 400px) {
    .tag-wp{
        width: 110%;
        margin-left: -5%;
    }
}