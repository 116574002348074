.history-section{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.history-section img{
    width: 80%;
    height: fit-content;
}

.history-section .title{
    text-align: center;
    color: black;
    font-size: 2rem;
    margin-bottom: 50px;
}

.slide-top{
    animation: slide-top linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
}
@keyframes slide-top{
    0%{
        opacity: 0;
        transform:translateY(0)
    }
    100%{
        opacity: 1;
        transform:translateY(-600px)
    }
}