.services{
    width: 100%;
    height: fit-content;
    padding: 0 8% 0 8%;
    display: flex;
    margin-top: 100px;
}
.content-wp{
    width: 40%;
    height: 100%;
}
.img-wp{
    width: 50%;
    height: 100%;
}

.content-wp>h1{
    font-size: 4.5rem;
    font-weight: 400;
}
.list-wp{
    margin-top: 50px;
}
.list{
    margin-top: 35px;
}
.list h3{
    font-size: 1.8rem;
    font-weight: 500;
}
.list p{
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: 300;
    color: #999999;
}

.img-wp{
    width: 60%;
    height: 100%;
}
.img-wp img{
    width: 90%;
    height: 90%;
    object-fit: contain;
    margin-top: -120px;
    margin-left: 10%;
}

@media screen and (max-width: 1200px) {
    .services{
        margin-top: 50px;
    }
    .content-wp>h1{
        font-size: 3.5rem;
    }
    .content-wp{
        width: 50%;
    }
    .img-wp img{
        width: 100%;
        height: 100%;
        margin-top: 0px;
    }
}

@media screen and (max-width: 1000px) {
    
    .content-wp>h1{
        font-size: 3rem;
    }
    .content-wp{
        width: 50%;
    }
    .list-wp{
        margin-top: 25px;
    }
    .list{
        margin-top: 35px;
    }
    .img-wp img{
        width: 110%;
        height: 110%;
        margin-left: 0%;
        margin-top: 0px;
    }
}

@media screen and (max-width: 850px) {
    .content-wp>h1{
        font-size: 2.5rem;

        font-weight: 400;
    }
    .content-wp{
        width: 50%;
        
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .services{

        flex-direction: column-reverse;
        margin-top: 0;
    }
    .content-wp{
        width: 100%;
        height: 100%;
    }
    .list h3{
        font-size: 1.5rem;
    }


    .img-wp{
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
    }
    .img-wp img{
        width: 100%;
        height: 100%;
        margin-left: 0%;
        margin-top: 0px;
    }

    
}
