.destinatons{
width: 100%;
height: fit-content;
margin-top: 150px;
margin-bottom: 75px;
display: flex;
align-items: center;
flex-direction: column;
}
.locTyp-wp{
    width: 100%;
    margin-top: 50px;
    padding: 0 8% 0 8%;
}
.locTyp-wp div{
    display: flex;
    justify-content: space-between;
}
.locTyp-wp .text{
    display: flex;
    align-items:last baseline;
}
.locTyp-wp hr{
    margin-top: 15px;
    color: #999999;
}
.ds-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8% 0 8%;
}
.ds-scroll-btn-wp{
    display: flex;
}
.scrl-btn{
    width: 50px;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding-left: 5px;
}
.scrl-btn:nth-child(1){
    margin-right: 25px;
}
.scrl-btn span{
    color: white;
    
}
.ds-header .text{
    width: 100%;
    font-size: 4rem;
    font-weight: 500;
}

.destCard-wp{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* overflow: hidden; */
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* border-bottom: 1px solid black; */
    margin-top: 80px;
    /* padding: 0% 8% 50px 8%; */
    padding-bottom: 50px;
}

.show-less{
    flex-wrap: nowrap;
    padding: 0% 8% 50px 8%;
    justify-content: start;
}

.showMoreBtn, .showLessBtn {
    padding: 10px 20px !important;
    background-color: black !important;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, .19), hsla(0, 0%, 100%, 0) 49.71%);
    border: 0 !important;
    border-radius: 20px !important;
    color: white !important;
    text-transform: none !important;
    outline: none !important;
}
.showLessBtn{
    
}
.showMoreBtn div{
    white-space: nowrap;
    text-decoration: none;
    color: white;
    transform: rotate(-90deg);
    position: sticky;
    top: 0;
    right: 0;
}
.showLessBtn {
    background-color: red !important;
    position: sticky !important;
    top: 100px !important;
    margin-left: auto !important;
    margin-right: 8% !important;
}

@media screen and (max-width: 1200px) {
    .ds-header .text{
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 1000px) {
    .ds-header .text{
        font-size: 3rem;
    }
}

@media screen and (max-width: 730px) {
    .ds-header .text{
        text-align: center;
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 450px) {
    .ds-header .text{
        width: 100%;
        font-size: 2.5rem;
    }
}
