*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --grey: #999999;
  --orange: #f07c00;
  --body: #f5f5f5;
  --darkBlue: #0e2f75;
  --lightBlue: #0ca6e8
}
a{
  text-decoration: none;
}

.lora-font{
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: lighter;
  font-style: normal;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
