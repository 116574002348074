.about{
    width: 100%;
    height: 100%;
}
.bottomImg{
    width: 100%;
    height: 20%;
}
.about .block1{
    display: flex;
    padding: 0 10%;
}
.about .block2{
    display: flex;
    padding: 0 10%;
}
.block1 .text{
    width: 60%;
    padding-left: 25px;
    height: fit-content;

}
.block1 .text p{
    margin-bottom: 15px;
}
.block1 .img{
    width: 33%;
    height: 250px;
    margin-left: 2%;
}
.block2 .text{
    width: 50%;
    padding-right: 15px;
    text-align: right;
    height: fit-content;

}
.block2 .text p{
    margin-bottom: 15px;
}
.block2 .img{
    width: 40%;
    height: 350px;
    margin-top: -80px;
}

.block3{
    margin-top: 80px;
}


@media screen and (max-width: 900px) {
    .about .block1{
        padding: 0;
    }
    .about .block2{
        padding: 0;
    }
    .block2 .img{
        width: 40%;
        height: 350px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 850px) {
    .block1 .img{
        width: 50%;
        height: 300px;
        margin-left: 0%;
    }
    .block2 .img{
        width: 50%;
        height: 350px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 850px) {
    .about .block1{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .about .block2{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
   
    
    .block1 .img{
        width: 250px;
        height: 300px;
    }
    .block2 .img{
        width: 250px;
        height: 350px;
        margin-top: -0px;
    }
    .block1 .text{
        width: 100%;
        text-align:justify;
        height: fit-content;
        padding: 0 15px 0 15px;
    }
    .block2 .text{
        width: 100%;
        text-align:justify;
        height: fit-content;
        padding: 0 15px 0 15px;
    }
}

