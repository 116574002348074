.destCard{
    min-width: 400px;
    height: 400px;
    background-color: white;
    box-shadow:  1px 15px 15px #44444418;
    margin: 15px;
    border-radius: 35px;
    padding: 10px;
}
.dc-content-wp{
    padding-left: 10px;
    padding-right: 10px;
}
.dc-img{
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 25px;
}
.dc-title{
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 15px;
}
.dc-place{
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 15px;
    color: var(--grey);
    display: flex;
    align-items: center;
}
.dc-place span{
    color: black;
    margin-right: 6px;
}
.dc-booknow-wp{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.dc-booknow-btn{
    margin-left: auto !important;
}

.destDetail{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dc-popup-img{
    width: 100%;
    height: 60vh;
    border-radius: 25px;
}

.destDetail .content-box{
    margin-top: 25px;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: flex-end; */
    
}
.destDetail .content-title{
    /* display: inline-block; */
    margin-left: 30px;
    padding-right: 30px;
    color: black;
    font-size: 1.8rem;
    font-weight: 600;
}
.destDetail .content-sub-title{
    font-size: 0.95rem;
    color: var(--orange);
    font-weight: bold;
    margin-left: 30px;
    margin-bottom: 5px;
}

.destDetail .content-body{
    margin-top: 10px;
    margin-left: 20px;
    padding-right: 20px;
    color: var(--grey);
    font-size: 1rem;
    text-align: justify;
}

.right-side{
    text-align: end;
}

hr.style-two {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.content-title span{
    color: var(--orange);
    font-size: 1rem;
    font-weight: bolder;
    word-spacing: -2px;
}

.it-wp{
    margin-top: 15px;
}
.it-header-wp{
    width: 100%;
}
.it-header{
    display: flex;
    width: 100%;
}
.Mui-expanded .it-header h4{
color: var(--orange);
}
.it-header p{
    margin-left: 15px;
    font-weight: bold;
}

.it-tags-wp{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
}

.it-body{
    width: 100%;
}
.it-body h4{
    width: 100%;
}
.it-icons-wp{
    width: 100%;
    display: flex;
    padding-left: 5%;
    margin-top: 0px;
    flex-wrap: wrap;
}
.it-icon-box{
    margin: 20px 20px 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.it-icon-box img{
    width: 30px;
    margin-bottom: 10px;
}
.it-icon-box p{
    text-align: center;
    font-weight: 400;
    font-size: 0.7rem;
}
.it-body p{
    width: 100%;
    margin: 15px 0 15px 0;
}

.img-scroller{
    width: 100%;
    height: 300px;
    padding: 15px;
    display: flex;
    overflow-x: scroll;
}
.img-scroller img{
    width: 350px;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 15px;
    box-shadow: 1px 1px 15px 1px rgba(128, 128, 128, 0.568);
}




@media screen and (max-width: 650px) {
    .dc-popup-img{
        height: 40vh;
    }
    .destDetail .content-title{
        font-size: 1.5rem;
    }
    .destDetail .content-body{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 650px) {
    .destDetail .content-title {
        margin-left: 0;
        padding-right: 0px;
        text-align: center;
    }
    .destDetail .content-sub-title{
        font-size: 0.8rem;
        text-align: center;
    }
    .it-header h4{
        color: var(--orange);
        font-size: 0.8rem;
        }
    .it-header p{
            margin-left: 15px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .it-body h4{
            font-size: 0.8rem;
        }
        .it-body p{
            font-size: 0.8rem;
        }

}



@media screen and (max-width: 450px) {
    .destCard{
        min-width: 350px;
        height: 350px;

    }
    .dc-img{
        height: 55%;
    }
    .dc-title{
        font-size: 1.6rem;
    }
    .it-header h4{
        width: 30%;
    }
}

@media screen and (max-width: 400px) {
    .destCard{
        min-width: 300px;
    }
    .dc-img{
        height: 55%;
    }
    .dc-title{
        font-size: 1.4rem;
    }

    .dc-popup-img{
        height: 30vh;
    }
    .it-header h4{
        width: 40%;
    }
}