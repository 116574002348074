.OurFeatures{
    width: 100%;
    height: fit-content;
    margin-top: 100px;
    overflow: hidden;
}
.of-header{
    text-align: center;
    font-weight: 500;
    font-size: 3.5rem;
    margin-bottom: 60px;
}
.of-body{
    width: 100%;
    height: fit-content;
    padding: 0% 8% 0% 8%;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.f-card{
    width: 28%;
    height: fit-content;
}
.fc-header{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fc-num-wp{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.fc-num-wp .text{
    color: white;
    font-size: 1.5rem;
}
.fc-header>.text{
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}
.divider{
    border: 1px dashed #999999;
}
.fc-body{
    margin-top: 25px;
    width: 100%;
}
.fc-body .text{
    font-size: 0.9rem;
    text-align: center;
    color: #999999;
}

@media screen and (max-width: 550px) {
    .of-body{
        flex-direction: column;
        margin-bottom: 50px;
    }
    .of-header{
        margin-bottom: 40px;
        
    }
    .f-card{
        width: 100%;
        margin-top: 25px;
    }
    .fc-header{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .fc-header>.text{
        text-align: start;
    }

    .fc-num-wp{
        margin-bottom: 0px;
        margin-right: 5%;

        width: 75px;
        height: 75px;
        border-radius: 50%;
        
    }
    .fc-body{
        margin-bottom: 35px;
    }

}
@media screen and (max-width: 480px) {
    .OurFeatures{
        width: 100%;
        height: fit-content;
        margin-top: 70px;
        overflow: hidden;
    }
    .of-header{
        font-size: 3rem;
        margin-bottom: 40px;
    }
    .fc-num-wp{
        margin-bottom: 0px;
        margin-right: 5%;

        width: 50px;
        height: 50px;
        border-radius: 50%;
        
    }
    .fc-header>.text{
        text-align: start;
        font-size: 1.5rem;
    }
}