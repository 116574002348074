.mice-banner{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url("../../../../public/mice/mice-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.mice-banner .content-wp{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.bg-dark{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: black;
    opacity: .4;
}
.companyLogo{
    width: clamp(300px, 75%, 550px);
    height: fit-content;
    padding: 45px;
    background-color: white;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
}
.companyLogo img{
    width: 100%;
    height: fit-content;
    object-fit: contain;
}
.company-name{
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 5px;
}

@media screen and (max-width: 650px) {
.company-name{
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 5px;
}
}


