.photoGallary{
    width: 100%;
    height: fit-content;
    padding: 0 8% 0 8%;
    background-color: black;
    padding: 50px 0;
}
.gallary-wp{
    width: 100%;
    height: fit-content;
    display: flex;
    margin: 50px 0;
}
.content{
    width: 40%;
    flex-grow: 1;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 25px
}
.photo-scroller{
    width: 60%;
    height: 100%;
    overflow-x: scroll;
}






.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .container .glass {
    position: relative;
    min-width: 250px;
    min-height: 250px;
    width: 250px;
    height: 250px;
    padding: 8px 8px 0 8px;
    /* background: linear-gradient(#fff2, transparent);
    border: 1px solid rgba(255, 255, 255, 0.1); */
    background-color: #1c1c1c;;
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: start;
    transition: 0.5s;
    border-radius: 10px;
    margin: 0 -45px;
    /* backdrop-filter: blur(10px); */
    transform: rotate(calc(var(--r) * 1deg)); 
  }
  
  .container:hover .glass {
    transform: rotate(0deg);
    margin: 0 10px;
  }
  
  .container .glass::before {
    content: attr(data-text);
    text-align: center;
    position: absolute;
    font-size: 0.8rem;
    padding: 0 8px;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .container .glass img{
    width: 100%;
    height: calc(100% - 40px);
    object-fit: cover;
    border-radius: 2px;
  }
  
  .hidden-class{
    display: none !important
  }


  @media screen and (max-width: 1200px) {
    .container .glass {
        min-width: 150px;
        min-height: 200px;
        width: 25%;
        height: 200px;
      }
      
}

@media screen and (max-width: 1000px) {

    .gallary-wp{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column !important;
    }
    .content{
        width: 100%;
        flex-grow: 1;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0 25px;
    }
    .content h1{
        width: 100%;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 2rem;
    }
    .photo-scroller{
        width: 100%;
        height: 100%;
        overflow-x: scroll;
    }
}

  