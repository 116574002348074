.contactCardSection{
    width: 100%;
    height: fit-content;
    padding: 65px 8% 25px 8%;
    /* margin-bottom: 50px; */
    background-color: #3b3b3b;
    color: white;
}
.cards-wp{
    width: 100%;
    height: fit-content;
    display: flex;
    /* overflow: hidden; */
    overflow-y: scroll;
    /* flex-wrap: wrap; */
    margin-top: 25px;
    padding-bottom: 50px;
}
/* .contactCardSection img{
    min-width: 550px;
    height: 400px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 25px;
    box-shadow: 1px 1px 15px 1px #d3d3d3a8;
} */
.contact-table{
    min-width: 300px;
}
.contact-table td{
align-content: start;
/* color: #444; */
}
.contact-table .key{
    width: 100px;
    font-weight: bold;
}
/* .contact-divider-wp{
    transform: rotate(90deg);
    width: 200px;
    height: 1px;
} */
.contact-divider{
    margin: 0 35px 0 25px;
}

.contact-divider:last-child{
    display: none;
}