.Banner{
    width: 100%;
    height: 80vh;
    margin-top: 80px;
    display: flex;
}
.bn-1{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
}
.dis-logo{
    width: fit-content;
    height: 37px;
    border: 1px solid black;
    padding: 5px 10px 5px 5px;
    border-radius: 33px;
    display: flex;
    align-items: center;
}
.dis-logo .circle{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid black;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.send-icon{
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-bottom: 2px;
    transform: rotate(-45deg);
}
.dis-logo .text {

}
.big-tagline{
    margin-top: 15px;
    width: 90%;
}
.big-tagline .text{
    font-weight: 500;
    font-size: 5rem;
}
.para-wp{
    margin-top: 25px;
    padding-left: 5%;
}
.para-wp .text{
    font-size: 1.2rem;
    font-weight: 300;
    color: #999999;
}

.bn-2{
    width: 50%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.bn-2-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.bn-2-airplane-icon{
    position: absolute;
    width: 150px;
    height: 150px;
    top: 10px;
    z-index: 5;
    left: -50px;
}


@media screen and (max-width: 1200px) {
    .big-tagline .text{
        font-size: 4rem;

        font-weight: 500; 
    }
    .para-wp .text{
        font-size: 1rem;

        font-weight: 300;
        color: #999999;
    }
    .bn-2-airplane-icon{
        width: 120px;
        height: 120px;

        position: absolute;      
        top: 10px;
        z-index: 5;
        left: -50px;
    }
}

@media screen and (max-width: 1050px) {
    .big-tagline .text{
        font-size: 3.5rem;
    }
    .para-wp .text{
        font-size: 0.9rem;

    }
    .bn-2-airplane-icon{
        width: 120px;
        height: 120px;
        top: 20px;
        left: -30px;  
    }
}
@media screen and (max-width: 930px) {
    .big-tagline .text{
        font-size: 3rem;
    }
    .para-wp .text{
        font-size: 0.9rem;

    }

}

@media screen and (max-width: 730px) {
    .Banner{
        width: 100%;
        height: fit-content;
        flex-direction: column-reverse;
        margin-top: 0;
    }
    .bn-1{
        width: 100%;
        height: fit-content;
        padding-left: 10%;
        padding-right: 10%;
        align-items: center;
        margin-bottom: 50px;
    }
    .big-tagline{
        width: 100%;
    }
    .big-tagline .text{
        text-align: center;
        font-size: 4rem;
    }
    .para-wp{
        padding-left: 0%;
    }
    .para-wp .text{
        text-align: center;
        font-size: 1.1rem;
    }


    .bn-2{
        width: 100%;
        height: 50vh;
        margin-bottom: 25px;
    }
    .bn-2-image{
        margin-left: 15px;
    }
    .bn-2-airplane-icon{
        width: 60px;
        height: 60px;
        left: 0%;
    }

}
@media screen and (max-width: 500px) {
    .big-tagline .text{
        font-size: 3.5rem;
    }
    .para-wp .text{
        font-size: 0.9rem;

    }

}
@media screen and (max-width: 450px) {
    .big-tagline .text{
        font-size: 3rem;
    }

}

@media screen and (max-width: 400px) {
    .big-tagline .text{
        margin-left: -3.5%;
    }

}
