.videoSection{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.videoSection video{
    width: 100vw;
    border-radius: 0px;
}


@media screen and (max-width: 1200px) {
    .videoSection{
        margin-top: 100px;
    }
    
}

@media screen and (max-width: 730px) {
    .videoSection{
        margin-top: 100px;
    }
    .videoSection video{
        width: 100vw;
        border-radius: 0;
    }
    
}


