.Navbar{
    width: 100vw;
    height: 80px;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    z-index: 10;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 10px;
    
}
.mob-Navbar{
    height: 60px !important;
}

.nav-brand{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.nav-brand span{
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}
.brand-logo{
    width: 130px;
    object-fit: contain;
    margin-left: 0px;
    margin-top: 15px;
}
.nav-menu-icon{
    /* margin-left: -55px !important; */
}

.nav-tabs{
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    
}

.nav-tabs span{
    color: var(--lightBlue);
    margin: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}
.darkBlue{
    color: var(--darkBlue) !important;
}

.lightBlue{
    color: var(--lightBlue) !important;
}
.nav-contactBtn-wp{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 5px;
}
.nav-contactBtn {
    padding: 10px 20px !important;
    background-color: black !important;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, .19), hsla(0, 0%, 100%, 0) 49.71%);
    border: 0 !important;
    border-radius: 20px !important;
    color: white !important;
    text-transform: none !important;
    outline: none !important;
}
.nav-contactBtn a{
    color: white;
    text-decoration: none;
}
.about{
    font-size: 1.2rem;
    color: #999999;
}
.toggle-btn{
    cursor: pointer;
}


@media screen and (max-width: 930px) {
    .Navbar{
        flex-direction: column;
        height: fit-content;
        padding: 15px;
    }

    .nav-tabs{
        margin-top: 10px;
        align-items: start;
        flex-direction: column;
        padding-left: 0px;
        height: 40vh;
    }
    .nav-brand{
        width: 100%;
        justify-content: space-between;
        
    }
    .nav-tabs{
        width: 100%;
        
    }

    .nav-contactBtn-wp{
        width: 100%;
        justify-content: start;
    }

}

.light-btn a{
    color: black !important;
}
.light-btn{
    background-color: #f5f5f5 !important;
    border: 1px solid black !important;
}