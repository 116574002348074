.photo-card {
    width: clamp(300px, 60%, 50vw) ;
    background: white;
    padding: .4em;
    padding-bottom: 1.6em;
    border-radius: 6px;
    transform: skewX(10deg);
    border-bottom: 3px solid rgba(255, 255, 255, 0.440);
    border-left: 2px  rgba(255, 255, 255, 0.545) outset;
    box-shadow: -20px 25px 30px rgba(0, 0, 0, 0.280);
    transition: .4s;
  }
  .photo-card:hover {
    
    transform: skew(0deg)
    scale(1.25);
  }
  .photo-card-image {
    background-color: rgb(236, 236, 236);
    width: 100%;
    height: 300px;
    border-radius: 6px 6px 0 0;
  }
  
  .photo-card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }