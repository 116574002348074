.req-call-wp{
    margin-top: 25px;
    width: 100%;
    height: 70px;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8a8a8a;
}

.req-call-btn {
    /* width: 28% !important; */
    height: 50px;
    margin-left: auto !important;
    display: flex !important;
    align-items: center !important;
    padding: 0px 20px!important;
    background-color: black !important;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, .19), hsla(0, 0%, 100%, 0) 49.71%);
    border: 0 !important;
    border-radius: 30px !important;
    color: white !important;
    text-transform: none !important;
    outline: none !important;
}
.req-call-btn .text{
    color: white;
    margin-left: 5px !important;
    text-decoration: none;
}

.whatsAppIcon{
    width: 24px;
    height: 24px;
    filter: invert(1);
    margin-right: 3px;
}


@media screen and (max-width: 500px) {

    .req-call-wp{
        width: 100%;
        height: 60px;

    }
    .req-call-btn {
        /* width: 45%; */
        height: 50px;
        border-radius: 40px !important;
    }
}

@media screen and (max-width: 400px) {

    .req-call-btn {
        width: 100%;
    }
}


