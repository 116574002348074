.benifits-section{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
    background-color: black;
    padding: 150px 5% 80px 5%;
    color: white;
}
.benifits-section .header{
    text-align: center;
}
.benifits-section .header h1{
    font-size: 3rem;
    margin-bottom: 25px;
}
/* .flex-wp{
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
} */
/* .content-wp{
    width: 40%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.content-wp h1{
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 25px;
}
.content-wp li{
    font-size: 1.6rem;
}
.image-wp{
    width: 60%;
    height: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.curve-top{
    width: 100vw;
    height: 50px;
    border-radius: 80% 80% 0 0;
} */

.bento-container {
    display: grid;
    width: 100%;
    max-width: 1000px;
    height: 700px;
    /* height: 100%; */
    height: fit-content;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.5rem;
    margin-top: 80px;
  }
  article {
    /* min-height: 250px; */
    border-radius: 1rem;
    background-color: #1c1c1c;
    padding: 10px;
    overflow: hidden;
    /* opacity: 0;
    visibility: hidden; */
    transition: all 0.2s cubic-bezier(1,0,0,1);
    /* transform: scale(0.5); */
    &.reveal {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  article img{
    width: 100%;
    height: 50%;
    /* min-height: 250px; */
    border-radius: calc(1rem - 10px);
  }
  article h3{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  article ul{
    width: 80%;
    margin-top: 15px;
    margin-left: 10%;
  }
  article li{
    margin-bottom: 5px;
    width: 80%;
  }


.feature-wp{
    width: fit-content;
    margin: 100px 0 50px 0;
    text-align: center;
}
.feature-wp h1{
    font-size: 3rem;
    margin-bottom: 25px;
    
}
.feature-wp ul{
    list-style-type: none;
    font-size: 1.1rem;
}
  @media screen and (min-width: 0px) {
    
    .bento-container {
      grid-template-columns: repeat(1, 1fr);
    }

    article{
      height: fit-content;
    }

  }
  @media screen and (min-width: 540px) {
    
    .bento-container {
      grid-template-columns: repeat(2, 1fr);
    }
    article:nth-of-type(5) {
        grid-column: 2/3;
        grid-row: 2/4;
      }
  }
  @media screen and (min-width: 940px) {
    article{
      height: auto;
    }
    .bento-container {
        grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 4fr 3fr 3fr;
    }
    article {
      &:nth-of-type(1) {
        grid-row: 1/2;
      }
      
      &:nth-of-type(5) {
        grid-column: 2/4;
        grid-row: 2/4;
      }
    }
  }


@media screen and (max-width: 650px) {
    .benifits-section .header h1{
        font-size: 2rem;
        margin-bottom: 25px;
    }
    .feature-wp h1{
        font-size: 2rem;
        margin-bottom: 25px;
        
    }
}
  

